import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import PageHeader from '../components/page-header';
import PostList from '../components/post-list';
import styled from 'styled-components';

const Diensten = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes;

  return (
    <Layout title="Diensten">
      <PageHeader header>
        <h1>Diensten</h1>
      </PageHeader>

      <PostList posts={posts} />
    </Layout>
  );
};

export default Diensten;

export const dienstenPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fields: { contentType: { eq: "diensten" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        fields {
          slug
        }
        excerpt
        frontmatter {
          description
          title
          image
        }
      }
    }
  }
`;
